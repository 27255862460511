import Image from 'next/image'
import SslImg from '@qtc-repo/assets/images/ssl-secure.svg'
import InputHelper from './InputHelper'

const SslSecure = () => {
  return (
    <span data-testid="ssl-secure">
      <InputHelper text="This form is encrypted to ensure your data is safe" />
      <Image className="mt-5 w-28 sm:mx-0 md:w-32" src={SslImg} alt="Secure" />
    </span>
  )
}

export default SslSecure
