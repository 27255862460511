import { MouseEvent } from 'react'
import { useSystemValues } from '@/contexts/ValueContext'
import {
  excludeEmptyFields,
  formatUserDetails,
  hasObjectValueChanged,
  scrollToTop,
  toast as customToast,
} from '@qtc-repo/common/utils'
import { Brands, STEP, ToastError } from '@qtc-repo/common/constants'
import { nanoid } from 'nanoid'
import dynamic from 'next/dynamic'
import NextButton from '@qtc-repo/ui/components/Button/NextButton'
import Feature1 from '@qtc-repo/assets/images/feature-1.png'
import Feature2 from '@qtc-repo/assets/images/feature-2.png'
import Feature3 from '@qtc-repo/assets/images/feature-3.png'
import Feature4 from '@qtc-repo/assets/images/feature-4.png'
import { upsertDetails } from '../lib/utils'

export const _data = [
  {
    href: 'https://www.itv.com/thismorning/articles/did-you-know-you-can-get-ppi-payout-tax-back',
    image: Feature1,
  },

  {
    href: 'https://www.dailymail.co.uk/news/article-8610431/New-PPI-gold-rush-Ruling-hidden-fees-spark-millions-fresh-payout-claims.html',
    image: Feature2,
  },
  {
    href: 'https://www.mirror.co.uk/money/martin-lewis-tells-millions-brits-21425465',
    image: Feature3,
  },
  {
    href: 'https://www.bbc.co.uk/news/business-15701101',
    image: Feature4,
  },
]

const Spinner = dynamic(() => import('@/components/BackdropSpinner'))
const QtcHero = dynamic(() => import('@/components/QtcHero'), {
  loading: () => <Spinner />,
})
const Features = dynamic(() => import('@qtc-repo/ui/components/Features/Features'), {
  loading: () => <Spinner />,
})
const TempDetails = dynamic(() => import('@/components/TempDetails'), {
  loading: () => <Spinner />,
})
const BasicInfo = dynamic(() => import('@/components/BasicInfo/BasicInfo'), { loading: () => <Spinner /> })

const HeroSection = () => {
  const {
    userData,
    setUserData,
    firstEvents,
    setFirstEvents,
    dbData,
    catchError,
    utmParams,
    setDbData,
    setUserEmail,
    device,
    setSelectedAddress,
    manualAddr,
    useAutoComplete,
    linkCode,
    userIp,
    setStepLoading,
    brand,
    setStep,
    setReady,
    getNextStep,
    editingSummary,
    stepValid,
    stepLoading,
  } = useSystemValues()

  const isPageComplete = stepValid[STEP.NAME] && stepValid[STEP.DOB]

  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setFirstEvents({
      ...firstEvents,
      firstName: false,
      lastName: false,
      day: false,
      month: false,
      year: false,
    })

    if (!isPageComplete) {
      scrollToTop()
      const valid_year = userData.year && +userData.year >= 1923 && +userData.year <= 2005
      if (!valid_year || !userData.month || !userData.day) {
        customToast(ToastError.DOB)
      }
      if (!userData.lastName.length) {
        customToast(ToastError.LAST_NAME)
      }
      if (!userData.firstName.length) {
        customToast(ToastError.FIRST_NAME)
      }

      return
    }

    if (userData.email) {
      setStepLoading(true)
      let details = { ...userData }
      if (useAutoComplete) {
        const { addressLine1, addressLine2, townOrCity, postCode } = manualAddr
        if (!addressLine1 || !townOrCity) {
          details.address = '' //prevent proceeding
        } else {
          Object.assign(details, {
            address: `${addressLine1}, ${addressLine2 ? addressLine2 + ', ' : ''}${townOrCity}`,
            postCode,
          })
          setSelectedAddress(details.address)
          setUserData(prev => ({ ...prev, ...details }))
        }
      }

      const formattedDetails = formatUserDetails(excludeEmptyFields(details))

      if (hasObjectValueChanged(formattedDetails, dbData)) {
        if (dbData?.birthdate_str) {
          const old_year = +dbData.birthdate_str.substr(dbData.birthdate_str.lastIndexOf('/') + 1, 4)

          const new_year = +formattedDetails.birthdate_str.substr(
            formattedDetails.birthdate_str.lastIndexOf('/') + 1,
            4
          )
          if ((old_year >= 1993 && new_year < 1993) || (old_year < 1993 && new_year >= 1993)) {
            const supabase = (await import('@qtc-repo/common/client')).default
            if (old_year >= 1993) {
              await supabase.from('PPI_Claim_Form_1993_2005').delete().match({ email: userData.email })
            }
            if (new_year >= 1993) {
              await supabase.from('PPI_Claim_Form').delete().match({ email: userData.email })
            }
          }
        }
        const link_code = linkCode || nanoid(9)
        const { data, error } = await upsertDetails({
          ...utmParams,
          ...formattedDetails,
          link_code,
          link: `https://quicktaxclaims.co.uk?c=${link_code}`,
          user_ip: userIp,
          device,
          domain: 'https://quicktaxclaims.co.uk',
        })
        if (data) {
          setDbData(data)
          setUserEmail(userData.email)
        }
        if (error) {
          catchError(error)
        }
      }
    }
    setStepLoading(false)
    setStep(editingSummary ? STEP.SUMMARY : getNextStep())
    setReady(true)
  }

  return (
    <>
      <section className="bg-gradient-to-b from-gray-50 to-transparent dark:from-gray-900 dark:to-gray-900">
        {brand === Brands.QTC ? (
          <QtcHero />
        ) : (
          <div
            className="mx-auto max-w-screen-lg px-4 py-8 lg:px-12 lg:py-16"
            style={{ opacity: Number(brand !== null) }}
          >
            <h1 className="mb-4 text-center text-4xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-white md:text-5xl lg:text-6xl">
              Claim Your PPI Tax Refund Today!
            </h1>
            <p className="mb-8 text-center text-lg font-normal text-gray-500 dark:text-gray-400 sm:px-16 lg:mb-10 lg:text-xl xl:px-24">
              Now you can claim a tax refund in 60 seconds…
              <i>Guaranteed!</i> – simply enter your details below to get started!
            </p>
            <form className="mx-auto grid max-w-2xl grid-cols-1  gap-5 rounded-lg border border-gray-200 bg-white p-5 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:grid-cols-2">
              <BasicInfo />
              <div className={'mt-5 sm:col-span-2'}>
                <NextButton
                  onClick={handleClick}
                  timer={'It only takes a minute!'}
                  label={'Start now'}
                  loading={stepLoading}
                  secure
                />
              </div>
              <TempDetails />
            </form>
          </div>
        )}
      </section>

      <Features features={_data} header="PPI tax refunds has featured on" />
    </>
  )
}

export default HeroSection
